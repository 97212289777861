<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="/gbwhatsapp-apk-download">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="/disclaimer">Disclaimer</a>
          </li>
          <!-- @click="downloadURL()" -->
        </ul>
      </div>
    </div>
  </nav>
  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2025/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <h1 id="gbwhatsapp-pro" class="hd">
                      Download GB WhatsApp APK without Risks 2025
                    </h1>
                    <!-- </div> -->
                    <ul class="banner-table">
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/app.svg" alt="app icon">
                            App
                          </div>
                          <div class="flex-data">
                            GBWhatsApp
                          </div>
                        </li>
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/size.svg" alt="size icon">
                            Size
                          </div>
                          <div class="flex-data">
                            77 MB
                          </div>
                        </li>
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/version.svg" alt="version icon">
                            Version
                          </div>
                          <div class="flex-data">
                            18.20
                          </div>
                        </li>
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/update.svg" alt="update icon">
                            Last Updates
                          </div>
                          <div class="flex-data">
                            1 Day Ago
                          </div>
                        </li>

                        <li>
                          <div class="flex-data">
                            <img src="@/assets/developer.svg" alt="developer icon">
                            Developer
                          </div>
                          <div class="flex-data">
                            <a href="/" class="jump-url">GBWA</a>
                          </div>
                        </li>
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/system.svg" alt="system icon">
                            System
                          </div>
                          <div class="flex-data">
                            Android Only
                          </div>
                        </li>
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/root.svg" alt="root icon">
                            Root
                          </div>
                          <div class="flex-data">
                            No Need
                          </div>
                        </li>
                        <li>
                          <div class="flex-data">
                            <img src="@/assets/license.svg" alt="license icon">
                            License
                          </div>
                          <div class="flex-data">
                            $0
                          </div>
                        </li>
                    </ul>
                    <div class="bottom-wrapper">
                      <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                        <div class="wp-block-button">
                          <a class="wp-block-button__link wp-element-button" href="/gbwhatsapp-apk-download">Download GBWhatsApp
                          </a>
                        </div>
                      </div>
                      <div class="rating">
                        <div class="rating-star">
                          <img src="@/assets/star.svg" alt="star">
                          <img src="@/assets/star.svg" alt="star">
                          <img src="@/assets/star.svg" alt="star">
                          <img src="@/assets/star.svg" alt="star">
                          <img src="@/assets/star-grey.svg" alt="star">
                        </div>
                        <div class="rating-text">
                          4.4/5
                        </div>
                        <div class="rating-text">
                          694
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-if="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>
                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1</span> What is GBWhatsApp?</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2</span> What's the difference of GBWhatsApp and WhatsApp?</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3</span> How to download GBWhatsApp APK</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4</span> Feature of GB WhatsApp App</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5</span> How to transfer WhatsApp data to GBWhatsApp?</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6</span> Is GB WhatsApp Over?</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7</span> Difference of GBWhatsApp and WhatsApp Plus</a>
                      </li>
                    </ul>
                  </section>
                  <section class="table-content hide" v-else>
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>
                </div>

                <h2 class="h">
                  <span id="TBC_0"> What is GBWhatsApp?
                  </span>
                </h2>
                <p>
                  GB WhatsApp has gained immense popularity as a modified version of the official WhatsApp, offering a variety of features not available in the original app. However, users are often concerned about the risks associated with using modified apps. In this post, we'll explore how to download GB WhatsApp APK safely, its standout features, and how it compares to other mods like WhatsApp Plus.
                </p>

                <h2 class="h">
                  <span id="TBC_1">What's the Difference Between GB WhatsApp and WhatsApp?</span>
                </h2>
                <p>
                  While both apps allow for messaging and calling within the WhatsApp ecosystem, GB WhatsApp focuses on giving users more control over their app experience, such as the ability to hide typing status, customize themes, and use Ghost Mode. While official WhatsApp has more safety than GBWhatsApp APK.
                </p>
                <figure class="wp-block-table is-style-regular">
                  <table class="has-fixed-layout feature-table">
                    <thead>
                      <tr>
                        <td><strong>Features</strong></td>
                        <th>GB WhatsApp</th>
                        <th>Official WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ghost Mode</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>DND Mode</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>Themes</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>Hide Blue Ticks</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>Change Fonts</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>Anti-Revoked Message</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>Save Statuses</td>
                        <td>✔️</td>
                        <td>×</td>
                      </tr>
                      <tr>
                        <td>Share HD Images</td>
                        <td>Original</td>
                        <td>Compressed</td>
                      </tr>
                      <tr>
                        <td>File Sharing</td>
                        <td>2G</td>
                        <td>2G</td>
                      </tr>
                      <tr>
                        <td>Root</td>
                        <td>No</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Business Version</td>
                        <td>No</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>License</td>
                        <td>Free</td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>System Support</td>
                        <td>Android</td>
                        <td>Android, iOS, Web, Desktop</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_2">How to Download GB WhatsApp APK: Requirements and Steps </span>
                </h2>
                <h3>
                  Requirements:
                </h3>
                <ol>
                  <li>An Android device running version 5.0 or higher.</li>
                  <li>Enable "Unknown Sources" in your device settings.</li>
                  <li>Adequate storage space for the APK file and app installation.</li>
                </ol>

                <h3>
                  Steps to Download GB WhatsApp APK Safely:
                </h3>
                <ol>
                  <li>Visit a trusted website that provides the latest GB WhatsApp APK file.</li>
                  <li>Download the APK file onto your device.</li>
                  <li>Open the APK file and follow the on-screen instructions to install the app.</li>
                  <li>Launch GB WhatsApp, verify your phone number, and restore your chat backup if available.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_3"> Features of GB WhatsApp
                  </span>
                </h2>
                <h3>Ghost Mode</h3>
                <p>Stay invisible online by hiding your active status, even while using the app. You can also hide when you're viewing statuses, giving you complete anonymity.</p>

                <h3>GB Themes</h3>
                <p>Access an extensive library of themes to personalize your app's appearance. From minimalist designs to vibrant and artistic themes, you can modify the app interface to suit your taste.</p>

                <h3>Change Fonts</h3>
                <p>Choose from a variety of font styles to enhance the visual appeal of your chats. Whether you prefer playful, elegant, or bold fonts, GB WhatsApp offers numerous options for creative expression.</p>

                <h3>Go to the First Message</h3>
                <p>Quickly jump to the first message of any conversation for easy navigation. This feature is particularly useful for revisiting old messages or tracking the start of long conversations.</p>

                <h3>Shake to Meet New Friends</h3>
                <p>Shake your phone to connect with other GB WhatsApp users globally. This unique feature fosters new connections, making messaging more social and interactive.</p>

                <h3>App Lock</h3>
                <p>Protect your chats with a built-in lock feature for added security. You can set PINs, passwords, or fingerprints to ensure your conversations remain private.</p>

                <h3>Hide Typing Status</h3>
                <p>Prevent others from seeing when you're typing a message. This feature is ideal for maintaining privacy or taking your time to compose replies without feeling rushed.</p>

                <h3>Save Status</h3>
                <p>Download and save statuses from your contacts directly to your device. Whether it's a photo, video, or text status, you can keep a copy without asking the creator.</p>

                <h3>Hide Read Receipt, Blue Ticks</h3>
                <p>Control whether others see when you've read their messages. This gives you more freedom to manage conversations without pressure to reply immediately.</p>

                <h3>Anti-Revoked Messages</h3>
                <p>View messages that the sender has deleted for everyone. This feature lets you see the content of revoked messages, avoiding the curiosity of "What was that message about?"</p>

                <h3>Send Large Files</h3>
                <p>Share videos, photos, and documents with sizes larger than the official app's limit. You can send high-resolution files and large documents without compressing or splitting them.</p>

                <h3>Custom Privacy Settings</h3>
                <p>Customize privacy for specific contacts, such as hiding your last seen, status updates, or profile picture from selected users. This granular control lets you tailor your interactions.</p>

                <h3>DND Mode (Do Not Disturb)</h3>
                <p>Activate DND mode to disconnect GB WhatsApp from the internet while keeping your device online. This ensures you won't be disturbed by notifications when you need focus time.</p>

                <h3>Broadcast Messages to Groups</h3>
                <p>Send broadcast messages to multiple groups at once. This is a time-saving feature for announcements or sharing information with large audiences.</p>

                <h3>Pin More Chats</h3>
                <p>Unlike the official app, which limits the number of pinned chats to three, GB WhatsApp allows you to pin more conversations for quick access.</p>

                <h3>Auto Reply</h3>
                <p>Set up automatic replies for incoming messages, perfect for busy schedules or when you're away. You can customize responses for specific contacts or create a general auto-reply.</p>

                <h3>Expanded Emoji Options</h3>
                <p>Access a broader range of emojis to make your conversations more expressive. This includes custom emojis and stickers not found in the official app.</p>

                <h3>Multi-Account Feature</h3>
                <p>Use multiple WhatsApp accounts on the same device without the need for separate apps. This is ideal for managing personal and professional communications from one place.</p>

                <h3>Scheduled Messaging</h3>
                <p>Schedule messages to be sent at specific times. This feature is excellent for reminders, greetings, or planned communications.</p>

                <h3>Enhanced Group Features</h3>
                <p>GB WhatsApp offers extended group settings, such as increasing the number of group members, sending large files in groups, and enhanced admin controls.</p>

                <h2 class="h">
                  <span id="TBC_4"> How to Transfer WhatsApp Data to GB WhatsApp </span>
                </h2>
                <ol>
                  <li>
                    <strong>Backup Official WhatsApp Data:</strong> <br>
                    Open WhatsApp, go to Settings > Chats > Chat Backup, and create a backup.
                  </li>
                  <li>
                    <strong>Install GB WhatsApp:</strong> <br>
                    Download and install GB WhatsApp APK as described earlier.
                  </li>
                  <li>
                    <strong>Restore Chat Data:</strong> <br>
                    During the setup, select the option to restore chat history from your WhatsApp backup.
                  </li>
                  <li>
                    <strong>Verify and Use:</strong> <br>
                    Complete the phone number verification, and your chats will be available in GB WhatsApp.
                  </li>
                </ol>

                <h2 class="h">
                  <span id="TBC_5"> Is GB WhatsApp Over? </span>
                </h2>
                <p>
                  The increasing ban issues related to modded apps like GB WhatsApp have raised concerns among users. WhatsApp's official policy strictly prohibits modified versions, and users of these apps risk account bans. To reduce the risks, use an anti-ban version of GB WhatsApp, avoid excessive customization, and keep the app updated to the latest version.
                </p>

                <h2 class="h">
                  <span id="TBC_6"> Difference Between GB WhatsApp and WhatsApp Plus </span>
                </h2>
                <p>
                  Both GB WhatsApp and WhatsApp Plus are popular modded versions, but they cater to slightly different user needs:
                </p>
                <figure class="wp-block-table is-style-regular">
                  <table class="has-fixed-layout">
                    <tr>
                      <th>Feature</th>
                      <th>GB WhatsApp</th>
                      <th>WhatsApp Plus</th>
                    </tr>
                    <tr>
                      <td><strong>Customization</strong></td>
                      <td>Extensive themes and fonts</td>
                      <td>More focused on UI tweaks</td>
                    </tr>
                    <tr>
                      <td><strong>Privacy Options</strong></td>
                      <td>Ghost Mode, hide statuses</td>
                      <td>Hide statuses, dual accounts</td>
                    </tr>
                    <tr>
                      <td><strong>Unique Features</strong></td>
                      <td>Shake to meet friends, anti-revoke messages</td>
                      <td>Auto-reply, anti-ban measures</td>
                    </tr>
                    <tr>
                      <td><strong>Performance</strong></td>
                      <td>Regular updates</td>
                      <td>Stable but fewer updates</td>
                    </tr>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_7"> Conclusion </span>
                </h2>
                <p>GB WhatsApp continues to offer exciting features for users who want more than the official WhatsApp provides. While there are risks associated with using modded apps, downloading from trusted sources and using anti-ban measures can help mitigate these concerns. Compare the app with other mods like WhatsApp Plus to choose the one that best suits your needs.</p>

                <div class="inner-box">
                  <div class="navigation-branding">
                    <div class="site-logo">
                      <router-link :to="{ name: `${selectedLanguage}-gb` }" title="GBWhatsApp" rel="home">
                        <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
                      </router-link>
                    </div>
                    <p class="main-title" itemprop="headline">
                      <router-link :to="{ name: `${selectedLanguage}-gb` }" rel="home">
                        GBWhatsApp
                      </router-link>
                    </p>
                  </div>
                  <h2 class="h">
                    <span id="TBC_8"> The Best WhatsApp Mods Without Being Banned </span>
                  </h2>
                  <div class="btn-box">
                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <a href="/gbwhatsapp-apk-download" class="wp-block-button__link wp-element-button transparent-btn filled-btn">
                          <!-- <img src="@/assets/size.svg" alt="android icon"> -->
                          <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.9011 36H4.09863C5.10208 25.8934 13.6292 18 23.9999 18C34.3706 18 42.8977 25.8934 43.9011 36Z" fill="none" stroke="#000851" stroke-width="4" stroke-linejoin="round" />
                            <path d="M14 20L10 13" stroke="#000851" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M33 20L37 13" stroke="#000851" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="15" cy="29" r="2" fill="#000851" />
                            <circle cx="33" cy="29" r="2" fill="#000851" /></svg>
                          DOWNLOAD</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="/disclaimer">Disclaimer</a>
          |
          <a href="/about-us">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="downloadURL()">
    Download GBWhatsApp New
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import FAQ from '@/components/FAQ.vue';
// import utlisMethods from '@/utlis/global.js';
export default {
  name: 'Home',
  components: {
    FAQ,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      apk: null,
      from: 'gb',
      filename: 'gb',
      clickNum: 0,
      selectedLanguage: 'en',
      features: [{
          "title": "Ghost Mode",
          "text": "Enable this option from the top bar to stay online without letting others know. Whether you're browsing chats or replying to messages, Ghost Mode ensures your activity remains invisible to others."
        },
        {
          "title": "Anti-Revoke Messages",
          "text": "Tired of senders deleting messages before you read them? GB WhatsApp Pro has you covered. This feature lets you view deleted messages, making it a popular choice among users seeking more transparency in their chats."
        },
        {
          "title": "Theme Store",
          "text": "Personalize your messaging experience with access to a rich theme store. Choose from a variety of colorful and creative themes to revamp your WhatsApp interface, moving beyond the default dark and white themes."
        },
        {
          "title": "App Lock",
          "text": "Privacy is crucial, and GB WhatsApp Pro takes it seriously. Use the built-in app lock to secure your chats with a password, fingerprint, or PIN, ensuring that only you can access your messages."
        },
        {
          "title": "Advanced Media Sharing",
          "text": "Forget the limitations of the official app. With GB WhatsApp Pro, you can share up to <strong>100 images</strong> in one go and send large videos without compromising on quality or size."
        },
        {
          "title": "Schedule Messages",
          "text": "Never miss an important message or wish again! This feature lets you schedule messages to be sent automatically at a specific time, making it perfect for reminders, birthdays, or event announcements."
        },
        {
          "title": "Unread Messages",
          "text": "Mark messages as unread even after opening them. This feature is handy when you want to revisit a message later or give the impression that you haven't seen it yet."
        },
        {
          "title": "Save Status",
          "text": "Save your friends' WhatsApp statuses directly to your phone with just one tap. Whether it's an image, video, or text update, you can store it for future reference."
        },
        {
          "title": "Hide Read Receipts",
          "text": "Maintain your privacy by hiding read receipts. With this feature, you can read messages without the sender knowing you've seen them, giving you more control over your communication."
        },
        {
          "title": "Send Large Files",
          "text": "Easily send files up to <strong>700MB</strong> in size, including documents, videos, and audio files, making GB WhatsApp Pro ideal for professional and personal use."
        }
      ],
      faqs: [{
          question: 'What Should I Do If I Want to Switch to the Official Version?',
          answer: 'Backup your chats, uninstall GB WhatsApp, and download the official WhatsApp from the Play Store. Restore your chats during the setup.',
        },
        {
          question: 'What is the Difference Between GB WhatsApp and GB WhatsApp Pro?',
          answer: 'GB WhatsApp Pro offers additional features such as <strong>better anti-ban protection</strong>, enhanced <strong>privacy options</strong>, and a more user-friendly interface compared to GB WhatsApp.',
        }
      ],
      versions: [{
          "version": "v18.00",
          "size": "75 MB",
          "date": "August 2024"
        },
        {
          "version": "v17.85",
          "size": "70 MB",
          "date": "May 2024"
        },
        {
          "version": "v17.80",
          "size": "75 MB",
          "date": "May 2024"
        },
        {
          "version": "v17.76",
          "size": "75 MB",
          "date": "April 2024"
        },
        {
          "version": "v17.70",
          "size": "75 MB",
          "date": "March 2024"
        }
      ],
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      // if (typeof window === 'undefined') {
      //   // Server-side rendering logic
      //   return '/downloadpage'; // Adjust this based on your server-side logic
      // } else {
      //   // Client-side rendering logic
      //   let params = window.location.search;
      //   return `/downloadpage${params}`;
      // }
      // console.log(this.apk);
      // // this.showapk = true
      // if (this.apk) {
      //   this.$global.download(this.apk);
      // }
      window.location.href = '/gbwhatsapp-apk-download';

    },
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'download'
      });
    },
    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
</style>
