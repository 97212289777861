import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp v18.20| Download GB WhatsApp APK without Risks`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp apk, gbwhatsapp download, gbwhatsapp update',
			description: `GBWhatsApp is one of the popular WhatsApp mods. How to download GB WhatsApp APK on Android without being attacked by phishing site and malware?`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com'
				}
			]
		},
		component: Home,
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/gbwhatsapp-apk-download',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download GB WhatsApp and Enjoy Unlocked WhatsApp Features`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Enter this page to download GBWhatsApp APK for Android. Users can enjoy more features without limits than in the original WhatsApp. `,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/gbwhatsapp-apk-download/'
				}
			],
			
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/about-us',
		name: 'about',
		meta: {
			appname: 'GBWhatsApp',
			title: `About Us`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Your trusted source for information on GB WhatsApp and similar tools.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/about-us/'
				}
			],
			noindex: true,
		},
		component: () => import('@/views/about.vue'),
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/disclaimer/'
				}
			],
			noindex: true,
		},
		component: () => import('@/views/disclaimer.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
