import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			count: 1000,
			voteScore: null,
		},
		modules,
		mutations: {
			UPDATE_VOTING(state, num) {
				state.voteScore = num
			},
		},
	})
}
